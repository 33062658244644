import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"

const IndexPage = ({ data }) => {
    const { allMarkdownRemark } = data 
    const { frontmatter, html } = allMarkdownRemark.edges[0].node;
    const { image } = frontmatter;

    return (
      <Layout>
        <SEO
          title="Home"
          keywords={[`writer`, `rachel gerry`, `essays`, `critical theory`, `Toronto`]}
        />
        <div>
          <ImgContainer>
            <img src={image} alt="Headshot of Rachel Gerry"/>       
          </ImgContainer>
        </div>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </Layout>
    )
}
export default IndexPage

export const pageQuery = graphql`
  query HomePageQuery {
    allMarkdownRemark(filter: {frontmatter: {path: {eq: "/"}}}) {
      edges {
        node {
          frontmatter {
            path
            image
          }
          html
        }
      }
    }
  }
`;

const ImgContainer = styled.div`
  text-align: right;
  margin-right: 40px;
  @media(max-width: 768px) {
    margin:0 auto;
    text-align: center;
  }
`